import React from 'react'
import Link from 'gatsby-link'
import { graphql } from "gatsby"
import * as utils from '../../utils'
import Layout from "../../components/layout"

export const query = graphql`
  query CatalogQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {type:{glob: "*release"}}}) {
      edges {
        node {
          id
          fields {
            slug
            title
          }
          frontmatter {
            nid
            vid
            type
            date
            year
            cover_filepath
            image_filepath
            status
            official
            timestamp
          }
          excerpt
        }
      }
    }
  }
`

const CatalogPage = ({data}) => {
  var sortedreleases = data.allMarkdownRemark.edges
  .filter(t => t.node && t.node.fields && t.node.fields.slug)
  .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.type !== 'videorelease')
  .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.status === 1 && t.node.frontmatter.official === 1)
    .sort((a, b) => {
      if (a.node.frontmatter.date < b.node.frontmatter.date) return 1
      if (a.node.frontmatter.date > b.node.frontmatter.date) return -1
      return 0
    });

  return <Layout>
    <div className="contentheader">
      <h1>Releases {data && data.site.siteMetadata.title}</h1>
      <p>Our smorgosbord of music</p>
    </div>
    <div className="covers">
    {
      sortedreleases.map(t => {
        let img = t.node.frontmatter.cover_filepath || t.node.frontmatter.image_filepath
        return img ? <span key={t.node.id}>
          <Link to={'/' + t.node.fields.slug} className="cover">
            <img src={utils.getImageURL(img, 300)}
              alt={t.node.fields.title} width="150" height="150" />
          </Link>
        </span> : null
      })
    }
    </div>
    {/* <pre> {JSON.stringify(sortedreleases, null, 2)}</pre> */}
  </Layout>;
}

export default CatalogPage
